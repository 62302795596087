@font-face {
  font-family: "iconfont"; /* Project id 4466447 */
  src: url('iconfont.woff2?t=1712826455903') format('woff2'),
       url('iconfont.woff?t=1712826455903') format('woff'),
       url('iconfont.ttf?t=1712826455903') format('truetype');
}

.iconfont {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-search:before {
  content: "\e617";
}

.icon-down:before {
  content: "\e69b";
}

.icon-zhedie3:before {
  content: "\e633";
}

.icon-aui-icon-top:before {
  content: "\e62c";
}

.icon-zhiding:before {
  content: "\e612";
}

.icon-left:before {
  content: "\e628";
}

.icon-right:before {
  content: "\e642";
}

