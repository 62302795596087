@import "../../assets/styles/utils";
.page {
    display: flex;
    align-items: center;
    justify-content: center;
    @include res(margin-top,35px,(4 / 7));
    a {
        width: 34px;
        height: 34px;
        background: #e9e9e9;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 5px;
        font-family: Calibri;
        @include res(font-size,20px,(16 / 20));
        color: #606f7d;
        transition: all .3s;
        @include res(margin-right,24px,(1 / 2));
        &:last-child {
            margin-right: 0;
        }
        &:hover,&.active {
            background-image: linear-gradient(-80deg, 
            #2aacff 0%, 
            #1980cd 57%, 
            #07539a 100%);
            color: #fff;
        }
        span {
            font-size: 20px;
        }
    }
}