.hamburger {
  display: block;
  cursor: pointer;
  background-color: transparent;
  overflow: visible;
  position: relative;
  width: 28px;
  height: 19px;
}

.hamburger > div, .hamburger > view {
  display: block;
  top: 50%;
  margin-top: -1.5px;
}

.hamburger > div, .hamburger > div::before, .hamburger > div::after, .hamburger > view, .hamburger > view::before, .hamburger > view::after {
  width: 28px;
  height: 3px;
  background-color: #07539A;
  border-radius: 0;
  position: absolute;
  transition-property: all;
  transition-duration: 0.15s;
  transition-timing-function: ease;
}

.hamburger > div::before, .hamburger > div::after, .hamburger > view::before, .hamburger > view::after {
  content: "";
  display: block;
}

.hamburger > div::before, .hamburger > view::before {
  top: -8px;
}

.hamburger > div::after, .hamburger > view::after {
  bottom: -8px;
}

.hamburger.hamburger-1 > div, .hamburger.hamburger-1 > view {
  top: auto;
  bottom: 0;
  transition-duration: 0.15s;
  transition-delay: 0.15s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger.hamburger-1 > div::after, .hamburger.hamburger-1 > view::after {
  top: -16px;
  transition: top 0.3s 0.3s cubic-bezier(0.33333, 0.66667, 0.66667, 1), opacity 0.1s linear, background-color 0.3s linear;
}

.hamburger.hamburger-1 > div::before, .hamburger.hamburger-1 > view::before {
  transition: top 0.12s 0.3s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19), background-color 0.3s linear;
}

.hamburger.hamburger-1.active > div, .hamburger.hamburger-1.active view {
  transform: translate3d(0, -8px, 0) rotate(-45deg);
  transition-delay: 0.32s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}

.hamburger.hamburger-1.active > div::after, .hamburger.hamburger-1.active view::after {
  top: 0;
  opacity: 0;
  transition: top 0.3s cubic-bezier(0.33333, 0, 0.66667, 0.33333), opacity 0.1s 0.27s linear, background-color 0.3s linear;
}

.hamburger.hamburger-1.active > div::before, .hamburger.hamburger-1.active view::before {
  top: 0;
  transform: rotate(-90deg);
  transition: top 0.12s 0.18s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.15s 0.42s cubic-bezier(0.215, 0.61, 0.355, 1), background-color 0.3s linear;
}

.hamburger.hamburger-2 > div, .hamburger.hamburger-2 > view {
  top: auto;
  bottom: 0;
  transition-duration: 0.15s;
  transition-delay: 0.15s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger.hamburger-2 > div::after, .hamburger.hamburger-2 > view::after {
  top: -16px;
  transition: top 0.3s 0.3s cubic-bezier(0.33333, 0.66667, 0.66667, 1), opacity 0.1s linear, background-color 0.3s linear;
}

.hamburger.hamburger-2 > div::before, .hamburger.hamburger-2 > view::before {
  transition: top 0.12s 0.3s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19), background-color 0.3s linear;
}

.hamburger.hamburger-2.active > div, .hamburger.hamburger-2.active > view {
  transform: translate3d(0, -8px, 0) rotate(45deg);
  transition-delay: 0.32s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}

.hamburger.hamburger-2.active > div::after, .hamburger.hamburger-2.active > view::after {
  top: 0;
  opacity: 0;
  transition: top 0.3s cubic-bezier(0.33333, 0, 0.66667, 0.33333), opacity 0.1s 0.27s linear, background-color 0.3s linear;
}

.hamburger.hamburger-2.active > div::before, .hamburger.hamburger-2.active > view::before {
  top: 0;
  transform: rotate(90deg);
  transition: top 0.12s 0.18s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.15s 0.42s cubic-bezier(0.215, 0.61, 0.355, 1), background-color 0.3s linear;
}

.hamburger.hamburger-3 > div, .hamburger.hamburger-3 > view {
  top: 0;
  transition-duration: .4s;
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
  margin-top: 0;
}

.hamburger.hamburger-3 > div::before, .hamburger.hamburger-3 > view::before {
  top: 8px;
  transition: opacity 0.15s 0.4s ease, background-color 0.3s linear;
}

.hamburger.hamburger-3 > div::after, .hamburger.hamburger-3 > view::after {
  top: 16px;
  transition: transform 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55), background-color 0.3s linear;
}

.hamburger.hamburger-3.active > div, .hamburger.hamburger-3.active > view {
  transform: translate3d(0, 8px, 0) rotate(135deg);
  transition-delay: .1s;
}

.hamburger.hamburger-3.active > div::before, .hamburger.hamburger-3.active > view::before {
  transition-delay: 0s;
  opacity: 0;
}

.hamburger.hamburger-3.active > div:after, .hamburger.hamburger-3.active > view:after {
  transform: translate3d(0, -16px, 0) rotate(-270deg);
  transition-delay: .1s;
}

.hamburger.hamburger-4 > div, .hamburger.hamburger-4 > view {
  top: 0;
  transition-duration: .4s;
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
  margin-top: 0;
}

.hamburger.hamburger-4 > div::before, .hamburger.hamburger-4 > view::before {
  top: 8px;
  transition: opacity 0.15s 0.4s ease, background-color 0.3s linear;
}

.hamburger.hamburger-4 > div::after, .hamburger.hamburger-4 > view::after {
  top: 16px;
  transition: transform 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55), background-color 0.3s linear;
}

.hamburger.hamburger-4.active > div, .hamburger.hamburger-4.active > view {
  transform: translate3d(0, 8px, 0) rotate(-135deg);
  transition-delay: .1s;
}

.hamburger.hamburger-4.active > div::before, .hamburger.hamburger-4.active > view::before {
  transition-delay: 0s;
  opacity: 0;
}

.hamburger.hamburger-4.active > div:after, .hamburger.hamburger-4.active > view:after {
  transform: translate3d(0, -16px, 0) rotate(270deg);
  transition-delay: .1s;
}

.hamburger.hamburger-5 > div, .hamburger.hamburger-5 > view {
  margin-top: -2px;
  transition: background-color 0.2s 0.25s ease-in, background-color 0.3s linear;
}

.hamburger.hamburger-5 > div::before, .hamburger.hamburger-5 > view::before {
  left: 0;
  transition: transform 0.2s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.2s linear, left 0.2s 0.25s ease-in, background-color 0.3s linear;
}

.hamburger.hamburger-5 > div::after, .hamburger.hamburger-5 > view::after {
  top: 8px;
  right: 0;
  transition: transform 0.2s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.2s linear, right 0.2s 0.25s ease-in, background-color 0.3s linear;
}

.hamburger.hamburger-5.active > div, .hamburger.hamburger-5.active > view {
  transition-delay: 0s;
  transition-timing-function: ease-out;
  background-color: transparent;
}

.hamburger.hamburger-5.active > div::before, .hamburger.hamburger-5.active > view::before {
  left: -56px;
  top: -56px;
  transform: translate3d(56px, 56px, 0) rotate(45deg);
  transition: left 0.2s ease-out, top 0.05s 0.2s linear, transform 0.2s 0.25s cubic-bezier(0.075, 0.82, 0.165, 1), background-color 0.3s linear;
}

.hamburger.hamburger-5.active > div::after, .hamburger.hamburger-5.active > view::after {
  right: -56px;
  top: -56px;
  transform: translate3d(-56px, 56px, 0) rotate(-45deg);
  transition: right 0.2s ease-out, top 0.05s 0.2s linear, transform 0.2s 0.25s cubic-bezier(0.075, 0.82, 0.165, 1), background-color 0.3s linear;
}

.hamburger.hamburger-6 > div, .hamburger.hamburger-6 > view {
  margin-top: -2px;
  transition: background-color 0.2s 0.25s ease-in, background-color 0.3s linear;
}

.hamburger.hamburger-6 > div::before, .hamburger.hamburger-6 > view::before {
  left: 0;
  transition: transform 0.2s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.2s linear, left 0.2s 0.25s ease-in, background-color 0.3s linear;
}

.hamburger.hamburger-6 > div::after, .hamburger.hamburger-6 > view::after {
  top: 8px;
  right: 0;
  transition: transform 0.2s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.2s linear, right 0.2s 0.25s ease-in, background-color 0.3s linear;
}

.hamburger.hamburger-6.active > div, .hamburger.hamburger-6.active > view {
  transition-delay: 0s;
  transition-timing-function: ease-out;
  background-color: transparent;
}

.hamburger.hamburger-6.active > div::before, .hamburger.hamburger-6.active > view::before {
  left: -56px;
  top: 56px;
  transform: translate3d(56px, -56px, 0) rotate(-45deg);
  transition: left 0.2s ease-out, top 0.05s 0.2s linear, transform 0.2s 0.25s cubic-bezier(0.075, 0.82, 0.165, 1), background-color 0.3s linear;
}

.hamburger.hamburger-6.active > div::after, .hamburger.hamburger-6.active > view::after {
  right: -56px;
  top: 56px;
  transform: translate3d(-56px, -56px, 0) rotate(45deg);
  transition: right 0.2s ease-out, top 0.05s 0.2s linear, transform 0.2s 0.25s cubic-bezier(0.075, 0.82, 0.165, 1), background-color 0.3s linear;
}

.hamburger.hamburger-7 > div, .hamburger.hamburger-7 > view {
  top: 0;
  margin-top: 0;
}

.hamburger.hamburger-7 > div::before, .hamburger.hamburger-7 > view::before {
  top: 8px;
  transition-property: transform, opacity, background-color;
  transition-timing-function: ease;
  transition-duration: .2s;
}

.hamburger.hamburger-7 > div::after, .hamburger.hamburger-7 > view::after {
  top: 16px;
}

.hamburger.hamburger-7.active > div, .hamburger.hamburger-7.active > view {
  transform: translate3d(0, 8px, 0) rotate(45deg);
}

.hamburger.hamburger-7.active > div::before, .hamburger.hamburger-7.active > view::before {
  transform: rotate(-45deg) translate3d(-4px, -5px, 0);
  opacity: 0;
}

.hamburger.hamburger-7.active > div::after, .hamburger.hamburger-7.active > view::after {
  transform: translate3d(0, -16px, 0) rotate(-90deg);
}

.hamburger.hamburger-8 > div, .hamburger.hamburger-8 > view {
  top: 0;
  margin-top: 0;
}

.hamburger.hamburger-8 > div::before, .hamburger.hamburger-8 > view::before {
  top: 8px;
  transition-property: transform, opacity, background-color;
  transition-timing-function: ease;
  transition-duration: .2s;
}

.hamburger.hamburger-8 > div::after, .hamburger.hamburger-8 > view::after {
  top: 16px;
}

.hamburger.hamburger-8.active > div, .hamburger.hamburger-8.active > view {
  transform: translate3d(0, 8px, 0) rotate(-45deg);
}

.hamburger.hamburger-8.active > div::before, .hamburger.hamburger-8.active > view::before {
  transform: rotate(45deg) translate3d(-4px, -5px, 0);
  opacity: 0;
}

.hamburger.hamburger-8.active > div::after, .hamburger.hamburger-8.active > view::after {
  transform: translate3d(0, -16px, 0) rotate(90deg);
}

.hamburger.hamburger-9 > div, .hamburger.hamburger-9 > view {
  margin-top: -2px;
  transition-duration: .3s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger.hamburger-9 > div::before, .hamburger.hamburger-9 > view::before {
  transition: top 0.1s 0.34s ease-in, opacity 0.1s ease-in, background-color 0.3s linear;
}

.hamburger.hamburger-9 > div::after, .hamburger.hamburger-9 > view::after {
  transition: bottom 0.1s 0.34s ease-in, transform 0.3s cubic-bezier(0.55, 0.055, 0.675, 0.19), background-color 0.3s linear;
}

.hamburger.hamburger-9.active > div, .hamburger.hamburger-9.active > view {
  transform: rotate(225deg);
  transition-delay: 0.14s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}

.hamburger.hamburger-9.active > div::before, .hamburger.hamburger-9.active > view::before {
  top: 0;
  opacity: 0;
  transition: top .1s ease-out, opacity 0.1s 0.14s ease-out, background-color 0.3s linear;
}

.hamburger.hamburger-9.active > div::after, .hamburger.hamburger-9.active > view::after {
  bottom: 0;
  transform: rotate(-90deg);
  transition: bottom 0.1s ease-out, transform 0.3s 0.14s cubic-bezier(0.215, 0.61, 0.355, 1), background-color 0.3s linear;
}

.hamburger.hamburger-10 > div, .hamburger.hamburger-10 > view {
  margin-top: -2px;
  transition-duration: .3s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger.hamburger-10 > div::before, .hamburger.hamburger-10 > view::before {
  transition: top 0.1s 0.34s ease-in, opacity 0.1s ease-in, background-color 0.3s linear;
}

.hamburger.hamburger-10 > div::after, .hamburger.hamburger-10 > view::after {
  transition: bottom 0.1s 0.34s ease-in, transform 0.3s cubic-bezier(0.55, 0.055, 0.675, 0.19), background-color 0.3s linear;
}

.hamburger.hamburger-10.active > div, .hamburger.hamburger-10.active > view {
  transform: rotate(-225deg);
  transition-delay: 0.14s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}

.hamburger.hamburger-10.active > div::before, .hamburger.hamburger-10.active > view::before {
  top: 0;
  opacity: 0;
  transition: top .1s ease-out, opacity 0.1s 0.14s ease-out, background-color 0.3s linear;
}

.hamburger.hamburger-10.active > div::after, .hamburger.hamburger-10.active > view::after {
  bottom: 0;
  transform: rotate(-90deg);
  transition: bottom 0.1s ease-out, transform 0.3s 0.14s cubic-bezier(0.215, 0.61, 0.355, 1), background-color 0.3s linear;
}

.hamburger.hamburger-11 > div, .hamburger.hamburger-11 > view {
  margin-top: -2px;
  transition-duration: .1s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger.hamburger-11 > div::before, .hamburger.hamburger-11 > view::before {
  transition: top 0.1s 0.14s ease, opacity 0.1s ease, background-color 0.3s linear;
}

.hamburger.hamburger-11 > div::after, .hamburger.hamburger-11 > view::after {
  transition: bottom 0.1s 0.14s ease, transform 0.1s cubic-bezier(0.55, 0.055, 0.675, 0.19), background-color 0.3s linear;
}

.hamburger.hamburger-11.active > div, .hamburger.hamburger-11.active > view {
  transform: rotate(45deg);
  transition-delay: 0.14s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}

.hamburger.hamburger-11.active > div::before, .hamburger.hamburger-11.active > view::before {
  top: 0;
  opacity: 0;
  transition: top .1s ease, opacity 0.1s 0.14s ease, background-color 0.3s linear;
}

.hamburger.hamburger-11.active > div::after, .hamburger.hamburger-11.active > view::after {
  bottom: 0;
  transform: rotate(-90deg);
  transition: bottom 0.1s ease, transform 0.1s 0.14s cubic-bezier(0.215, 0.61, 0.355, 1), background-color 0.3s linear;
}

.hamburger.hamburger-12 > div, .hamburger.hamburger-12 > view {
  margin-top: -2px;
  transition-duration: .3s;
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
}

.hamburger.hamburger-12 > div::before, .hamburger.hamburger-12 > div::after, .hamburger.hamburger-12 > view::before, .hamburger.hamburger-12 > view::after {
  transition-duration: 0s;
  transition-delay: .1s;
  transition-timing-function: linear;
}

.hamburger.hamburger-12 > div::before, .hamburger.hamburger-12 > view::before {
  transition-property: top, opacity, background-color;
}

.hamburger.hamburger-12 > div::after, .hamburger.hamburger-12 > view::after {
  transition-property: bottom, transform, background-color;
}

.hamburger.hamburger-12.active > div, .hamburger.hamburger-12.active > view {
  transform: rotate(765deg);
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
}

.hamburger.hamburger-12.active > div::before, .hamburger.hamburger-12.active > div::after, .hamburger.hamburger-12.active > view::before, .hamburger.hamburger-12.active > view::after {
  transition-delay: 0s;
}

.hamburger.hamburger-12.active > div::before, .hamburger.hamburger-12.active > view::before {
  top: 0;
  opacity: 0;
}

.hamburger.hamburger-12.active > div::after, .hamburger.hamburger-12.active > view::after {
  bottom: 0;
  transform: rotate(90deg);
}

.hamburger.hamburger-13 > div, .hamburger.hamburger-13 > view {
  margin-top: -2px;
  transition-duration: .3s;
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
}

.hamburger.hamburger-13 > div::before, .hamburger.hamburger-13 > div::after, .hamburger.hamburger-13 > view::before, .hamburger.hamburger-13 > view::after {
  transition-duration: 0s;
  transition-delay: .1s;
  transition-timing-function: linear;
}

.hamburger.hamburger-13 > div::before, .hamburger.hamburger-13 > view::before {
  transition-property: top, opacity;
}

.hamburger.hamburger-13 > div::after, .hamburger.hamburger-13 > view::after {
  transition-property: bottom, transform, background-color;
}

.hamburger.hamburger-13.active > div, .hamburger.hamburger-13.active > view {
  transform: rotate(-765deg);
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
}

.hamburger.hamburger-13.active > div::before, .hamburger.hamburger-13.active > div::after, .hamburger.hamburger-13.active > view::before, .hamburger.hamburger-13.active > view::after {
  transition-delay: 0s;
}

.hamburger.hamburger-13.active > div::before, .hamburger.hamburger-13.active > view::before {
  top: 0;
  opacity: 0;
}

.hamburger.hamburger-13.active > div::after, .hamburger.hamburger-13.active > view::after {
  bottom: 0;
  transform: rotate(-90deg);
}

.page {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 35px;
}

@media (max-width: 1600px) {
  .page {
    margin-top: 32px;
  }
}

@media (max-width: 1366px) {
  .page {
    margin-top: 29px;
  }
}

@media (max-width: 1024px) {
  .page {
    margin-top: 26px;
  }
}

@media (max-width: 991px) {
  .page {
    margin-top: 23px;
  }
}

@media (max-width: 767px) {
  .page {
    margin-top: 20px;
  }
}

.page a {
  width: 34px;
  height: 34px;
  background: #e9e9e9;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  font-family: Calibri;
  font-size: 20px;
  color: #606f7d;
  transition: all .3s;
  margin-right: 24px;
}

@media (max-width: 1600px) {
  .page a {
    font-size: 19.2px;
  }
}

@media (max-width: 1366px) {
  .page a {
    font-size: 18.4px;
  }
}

@media (max-width: 1024px) {
  .page a {
    font-size: 17.6px;
  }
}

@media (max-width: 991px) {
  .page a {
    font-size: 16.8px;
  }
}

@media (max-width: 767px) {
  .page a {
    font-size: 16px;
  }
}

@media (max-width: 1600px) {
  .page a {
    margin-right: 21.6px;
  }
}

@media (max-width: 1366px) {
  .page a {
    margin-right: 19.2px;
  }
}

@media (max-width: 1024px) {
  .page a {
    margin-right: 16.8px;
  }
}

@media (max-width: 991px) {
  .page a {
    margin-right: 14.4px;
  }
}

@media (max-width: 767px) {
  .page a {
    margin-right: 12px;
  }
}

.page a:last-child {
  margin-right: 0;
}

.page a:hover, .page a.active {
  background-image: linear-gradient(-80deg, #2aacff 0%, #1980cd 57%, #07539a 100%);
  color: #fff;
}

.page a span {
  font-size: 20px;
}
