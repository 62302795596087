@import "./utils";
@import "~jason-css/src/responsive/index";
@import "../fonts/iconfont.css";

@font-face {
  font-family: 'Roboto-Black';
  src: url('../fonts/Roboto-Black.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Roboto-Regular';
  src: url('../fonts/Roboto-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Roboto-Bold';
  src: url('../fonts/Roboto-Bold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Roboto-Thin';
  src: url('../fonts/Roboto-Thin.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Calibri-Bold';
  src: url('https://ziti.dongbaqu.com/calibri-Bold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Calibri';
  src: url('https://ziti.dongbaqu.com/calibri-Normal.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Calibri-Light';
  src: url('https://ziti.dongbaqu.com/calibri-Light.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
*{
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  outline:none;
  //文字中空描边
  // color: transparent;
  // -webkit-text-stroke: 1px #fff;
  // -webkit-font-smoothing: antialiased;
}

body {
  font-family: 'Roboto-Regular';
  overflow-x: hidden;
  position: relative;
  color: $color-text;
  background-color: #fff;
  @include res(font-size,16px,(14 / 16));
  @include res(line-height,30px,(5 / 6));
}

img {
  display: block;
}
html {
  &::-webkit-scrollbar {
    width: 4px;
  }
  &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
      opacity: 0.2;
      background: #07539A;
  }
  &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
      border-radius: 0;
      background: fade(#fff, 30%);
  }
}

//清除浮动
.clear {
  zoom: 1;

  &::after {
    content: '';
    display: block;
    height: 0;
    visibility: hidden;
    clear: both;
  }
}


//主体内容部分宽度
.container {
  box-sizing: content-box;
  width: 100%;
  max-width: 1420px;
  @include res(padding-left, $container-gutter-base, $container-gutter-ratio);
  @include res(padding-right, $container-gutter-base, $container-gutter-ratio);

  &.container-1200 {
    max-width: 1200px;
  }
}

@media screen and (max-width: 1600px) {
  .container {
    box-sizing: border-box;
    max-width: 1330px;
  }
}

@media screen and (max-width: 1440px) {
  .container {
    max-width: 1200px;
  }
}
@media screen and (max-width: 1024px) {
  .container {
    max-width: 960px;
  }
}
@media screen and (max-width: 768px) {
  .container {
    max-width: 700px;
  }
}


//圆形进度条
@keyframes progress {
  0% {
    stroke-dashoffset: 400%;
  }

  50% {
    stroke-dashoffset: 200%;
  }

  100% {
    stroke-dashoffset: 0%;
  }
}

@keyframes progress1 {
  0% {
    stroke-dashoffset: 400%;
  }

  50% {
    stroke-dashoffset: 200%;
  }

  100% {
    stroke-dashoffset: 0%;
  }
}

@keyframes progress2 {
  0% {
    stroke-dashoffset: 400%;
  }

  50% {
    stroke-dashoffset: 200%;
  }

  100% {
    stroke-dashoffset: 0%;
  }
}

@keyframes rain {
  0% {
    opacity: 0;
    transform: translateY(-20%);
  }

  25% {
    opacity: 0.7;
    transform: translateY(-10%);
  }

  50% {
    opacity: 1;
    transform: translateY(0%);
  }

  75% {
    opacity: 0.7;
    transform: translateY(10%);
  }

  100% {
    opacity: 0;
    transform: translateY(20%);
  }
}
.nei-banner {
  position: relative;
  @include res(height,auto,(xs:200px));
  @include res(margin-top,80px,(xs:70px));
  img {
    max-width: 100%;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .container {
    position: absolute;
    @include res(bottom,96px,(20 / 96));
    left: 0;
    right: 0;
    margin: auto;
    .title {
      color: #fff;
      font-family: Roboto-Black;
      @include res(font-size,46px,(30 / 46));
      @include res(letter-spacing,3px,(2 / 3));
    }
  }
}
.swiper-button-prev:after, .swiper-rtl .swiper-button-next:after {
  content: "";
}
.swiper-button-next:after, .swiper-rtl .swiper-button-prev:after {
  content: "";
}

.nei-humburger {
  @include res(display,flex,(xs:none));
  align-items: center;
  justify-content: flex-start;
  font-family: Roboto-Light;
  @include res(font-size,18px,(16 / 18));
  a {
    color: #020202;
    transition: all .3s;
    &:hover,&.active {
      color: #07539a;
    }
  }
  span {
    display:block;
    @include res(margin-left,17px,(10 / 17));
    @include res(margin-right,17px,(10 / 17));
    &:last-child {
      display: none;
    }
  }
}
.pro-nav {
  // @include res(margin-top,40px,(1 / 2));
  @include res(padding-top,30px,(1 / 2));
  @include res(padding-bottom,30px,(1 / 2));
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
  background-color: #fff;
  z-index: 999;
  .container {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
  }
  .left {
      overflow: hidden;
      overflow-x: auto;
      ul {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          white-space: nowrap;
          li {
              font-family: Calibri;
              @include res(font-size,22px,(lg:20px,mmd:18px));
              color: #020202;
              @include res(margin-right,40px,(lg:20px,mmd:15px));
              transition: all .3s;
              font-weight: bold;
              border-bottom: 1px solid transparent;
              &:last-child {
                  margin-right: 0;
              }
              &:hover,&.active {
                  color: #07539a;
                  border-color: #07539a;
              }
          }
      }
  }
  .right {
      @include res(display,flex,(sm:none));
      align-items: center;
      justify-content: flex-start;
      font-family: Calibri-Light;
      @include res(font-size,22px,(lg:20px,mmd:16px));
      a {
          color: #020202;
          transition: all .3s;
          &:hover,&.active {
              color: #07539a;
          }
      }
      span {
          @include res(margin-left,15px,(lg:10px));
          @include res(margin-right,15px,(lg:10px));
          &:last-child {
              display: none;
          }
      }
  }
  &.fixed {
    position: fixed;
    @include res(top,80px,(sm:70px));
    left: 0;
    right: 0;
    margin: auto;
  }
}
