@import "../../assets/styles/utils";
footer {
    width: 100%;
    @include res(height,132px);
    background-color: #303942;
    display: flex;
    align-items: center;
    justify-content: center;
    ul {
        display: flex;
        align-items: center;
        justify-content: center;
        @include res(flex-wrap,nowrap,(xs:wrap));
        li {
            @include res(font-size,16px,(14 / 16));
            @include res(width,fit-content,(xs:100%));
            color: #ffffff;
            @include res(margin-right,40px,(1 / 2));
            text-align: center;
            &:last-child {
                margin-right: 0;
            }
        }
    }
}
.backtop {
    position: fixed;
    z-index: 999;
    right: 10px;
    @include res(bottom,50px);
    background: #fff;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all .3s;
    border-radius: 5px;
    box-shadow: rgba(0,0,0,.1) 0px 1px 3px;
    span {
        color: #07539A;
        font-size: 26px;
        transition: all .3s;
    }
    &:hover {
        background: #07539A;
        span {
            color: #fff;
        }
    }
}
.cookie {
    display: none;
    position: fixed;
    z-index: 99999;
    width: 100%;
    height: auto;
    bottom: 0;
    left: 0;
    background: #fff;
    @include res(padding-top,70px,(2 / 7));
    @include res(padding-bottom,60px,(1 / 3));
    @include res(padding-left,66px,(15 / 66));
    @include res(padding-right,66px,(15 / 66));
    box-sizing: border-box;
    .content {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        .text {
            @include res(width,815px,(lg:70%,xs:50%));
            color: #000000;
            h2 {
                font-family: Calibri-Bold;
                @include res(font-size,24px,(5 / 6));
                @include res(margin-bottom,25px,(3 / 5));
            }
            p {
                font-size: 12px;
                font-family: Calibri;
                line-height: 20px;
            }
            a {
                color: #07539a;
                font-size: 14px;
                text-decoration: underline;
                font-family: Calibri-Bold;
            }
        }
        .btn {
            a {
                display: flex;
                align-items: center;
                justify-content: center;
                @include res(width,240px,(15 / 24));
                @include res(height,50px,(4 / 5));
                @include res(border-radius,25px,(4 / 5));
                border: solid 1px #303942;
                @include res(margin-bottom,18px,(10 / 18));
                transition: all .3s;
                font-family: Calibri;
                @include res(font-size,20px,(4 / 5));
                color: #606f7d;
                &:last-child {
                    margin-bottom: 0;
                }
                &:hover {
                    color: #fff;
                    background-image:linear-gradient(-46deg,#2aacff 10%,#1980cd 46%,#07539a 65%);
                    border-color: transparent;
                }
            }
        }
    }
}